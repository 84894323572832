<template>
  <div class="wrapper-content">
    <div class="nav" v-if="$route.query.type">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
          {{
            type == 1
              ? "全民健身服务平台"
              : type == 2
              ? "竞技体育服务平台"
              : type == 3
              ? "体育产业服务平台"
              : ""
          }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="tabBox">
      <div
        :class="index + 1 == type ? 'selectSty' : ''"
        @click="tabClick(index + 1)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <!-- 全民健身服务平台 -->
    <div class="center">
      <div class="contBox" v-for="(item, index) in dataList" :key="index">
        <div class="contRight" v-if="index % 2 != 0">
          <!-- <img src="@/assets/image/zhty.png" alt="" /> -->
        </div>
        <div class="contLeft">
          <div class="titleBox">
            <div class="titleIcon"></div>
            <div class="title">
              <div>{{ item.title }}</div>
            </div>
          </div>
          <div class="contImg">
            <!-- <img src="@/assets/image/zhty.png" alt="" /> -->
          </div>
          <div class="contTitle">
            &nbsp;&nbsp;&nbsp;&nbsp;{{ item.content }}
          </div>
          <div class="contItem">
            <div v-for="(items, indexs) in item.function" :key="indexs">
              {{ items }}
            </div>
          </div>
        </div>
        <div class="contRight" v-if="index % 2 == 0">
          <!-- <img src="@/assets/image/zhty.png" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      dataList: [],
      tabList: ["全民健身服务平台", "竞技体育服务平台", "体育产业服务平台"],
      fitness: [
        {
          title: "官网宣传",
          content:
            "提供多样式的官网模板由用户自行选择，可自定义导航栏目、发布宣传信息。",
          function: [
            "新闻发布",
            "个性设置",
            "动态导航栏",
            "自选模板",
            "轮播图",
            "精彩图集",
            "友情链接",
            "底部自定义",
            "公众号同步",
          ],
        },
        {
          title: "单位服务",
          content:
            "实现在线化、移动化、自动化办公，满足上下级跨单位公文流转、审批，提升单位办公效率。",
          function: ["组织管理", "OA办公", "单位资料", "通讯录等"],
        },
        {
          title: "会员管理",
          content:
            "支持单位自定义个人会员、单位会员申请表单、设置会员费用体系，实现会员在线申请、审核及缴费，在线生、打印成会员证。",
          function: [
            "会员配置",
            "会员注册",
            "会员审核",
            "会费管理",
            "注册表",
            "自定义注册表",
            "电子证等",
          ],
        },
        {
          title: "赛事活动",
          content:
            "单位可申报/审核、发布线上/线下赛事活动，自定义赛事活动属性，并根据上级需要上报或查看下属单位赛事活动数据。",
          function: ["赛事活动信息配置", "报名约束", "报名管理", "成绩管理等"],
        },
        {
          title: "培训教学",
          content:
            "自定义管理培训基本信息、课程内容、培训教员等资料，满足业余体育爱好者及专业体育人才的线上学习、线下培训需要。",
          function: [
            "培训信息",
            "课程设置",
            "教练管理",
            "学员管理",
            "考勤/考核",
            "培训报名",
            "试题管理",
            "线上教学",
            "在线支付",
          ],
        },
        {
          title: "赞助商服务",
          content:
            "为赛事活动赞助方和被赞助方提供数据资料上传和赛事概况查询入口，自定义效益评估体系，实现在线打分。",
          function: [
            "项目概况",
            "项目资料",
            "赞助商展区",
            "评估体系",
            "效果评价",
          ],
        },
      ],
      competitive: [
        {
          title: "人员管理",
          content:
            "按年度对运动员、教练员、裁判员、工作人员等信息进行注册、申报和审核，行程人员信息资料库。",
          function: ["人员注册", "资料上传", "年度申报", "信息审核"],
        },
        {
          title: "竞赛管理",
          content:
            "支持由体育行政部门、社团组织和企业等各级别、类型组织单位发布体育赛事，实现赛事信息配置至成绩发布全流程管理。",
          function: [
            "赛事管理",
            "竞赛报名",
            "赛程编排",
            "单项与综合成绩",
            "证书管理",
            "综合统计分析",
            "风险管理等",
          ],
        },
        {
          title: "赛会管理",
          content:
            "依托全套的GMS系统，为接待、抵离、交通、注册制证等提供支持保障服务。",
          function: [
            "礼宾抵离",
            "接待酒店",
            "交通服务",
            "注册制证",
            "物资物流",
            "运动员村",
            "员工管理",
            "通讯服务等",
          ],
        },
        {
          title: "后备人才基地管理",
          content: "为后备人才基地申报工作提供线上申报、审核服务。",
          function: [
            "基础信息",
            "管理制度",
            "专项训练设备",
            "科研医务设备",
            "信息申报等",
          ],
        },
        {
          title: "科医管理",
          content:
            "将科研与医务相结合，对运动员综合素质数据进行线上管理，为运动员选材提供数据支撑。",
          function: ["科研与医务管理", "运动员选材等"],
        },
      ],
      industry: [
        {
          title: "体育产业名录库",
          content:
            "建立省级名录库系统，面向省市层级，实现信息导入导出、系统去重、省局初筛、地市核查与上报、省局审批、信息查询、统计分类与打印等功能。",
          function: [
            "基础信息配置",
            "名录库数据处理",
            "名录库数据稽核",
            "数据回收站",
            "工作绩效",
            "查询统计",
          ],
        },
        {
          title: "体育产业专项调查",
          content:
            "按专项调查类目进行专项调查信息的填报（可对名录库信息进行随机抽样）、上报和审核。",
          function: ["登记", "审核", "统计", "分析"],
        },
        {
          title: "体育产业基地",
          content:
            "为示范基地、示范单位与示范项目的申报、评审与跟踪等工作提供日常管理与服务。",
          function: [
            "基础信息配置",
            "申报单位注册",
            "评选专家库",
            "评选标准库",
            "信息申报与评定",
            "体育产业基地建设库",
          ],
        },
        {
          title: "体育场地统计调查",
          content:
            "参照国家体育总局的场地分类和数据要求，设置统一场地分类细项。实现各成绩数据的上传、下载等同步功能，同时满足上级部门和本单位对体育场地数据格式、信息项等需要。",
          function: [
            "数据同步",
            "信息拓展",
            "场地基本信息",
            "场地位置信息",
            "场地分布信息",
            "场地现状等",
          ],
        },
      ],
    };
  },
  mounted() {
    this.type = this.$route.query.type || 1;
    this.initData();
    this.goPost();
  },
  methods: {
    goPost() {
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
      let step = distance / 30; //每步的距离
      (function jump() {
        if (distance > 0) {
          distance -= step;
          window.scrollTo(0, distance);
          setTimeout(jump, 10);
        }
      })();
    },
    tabClick(num) {
      this.type = num;
      this.initData();
    },
    initData() {
      this.dataList =
        this.type == 1
          ? this.fitness
          : this.type == 2
          ? this.competitive
          : this.type == 3
          ? this.industry
          : [];
    },
  },
};
</script>
<style lang="scss" scoped>
.tabBox {
  width: 100%;
  margin-top: 40px;
  div {
    display: inline-block;
    margin-right: 80px;
    cursor: pointer;
  }
  .selectSty {
    position: relative;
    color: #1e50ae;
    font-weight: bold;
    &::after {
      position: absolute;
      content: "";
      width: 46px;
      height: 2px;
      background-color: #1e50ae;
      bottom: -6px;
      left: 5%;
      border-radius: 4px;
    }
  }
}
.nav {
  margin-top: 16px;

  .breadcrumb {
    font-size: 13px;
    color: #666666;
  }
}
.center {
  width: 100%;
  padding: 80px 0;
  .contBox {
    width: 100%;
    display: flex;
    // padding: 40px 0;
    justify-content: space-between;
    .contLeft {
      width: 620px;
      line-height: 32px;
      padding: 0 30px;
      box-sizing: border-box;
      .contImg {
        display: none;
      }
      .titleBox {
        // .titleIcon {
        //   position: relative;
        //   width: 8px;
        //   height: 34px;
        //   display: inline-block;
        //   vertical-align: bottom;
        //   margin-right: 2px;
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     bottom: 0;
        //     left: 0;
        //     z-index: -1;
        //     background: #1e50ae;
        //     transform: skew(-20deg);
        //     -ms-transform: skew(-20deg); /* IE 9 */
        //     -moz-transform: skew(-20deg); /* Firefox */
        //     -webkit-transform: skew(-20deg); /* Safari 和 Chrome */
        //     -o-transform: skew(-20deg);
        //   }
        // }
        .title {
          position: relative;
          width: 230px;
          height: 34px;
          line-height: 34px;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          display: inline-block;
          text-align: center;
          transform: skewX(-45deg);
          box-shadow: 18px 10px 6px 2px rgba(30, 80, 174, 0.1);
          background-color: #1e50ae;
          div {
            transform: skewX(45deg);
          }
          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   right: 0;
          //   bottom: 0;
          //   left: 0;
          //   z-index: -1;
          //   background: linear-gradient(270deg, #5085ea 0%, #1e50ae 100%);
          //   transform: skew(-20deg);
          //   -ms-transform: skew(-20deg); /* IE 9 */
          //   -moz-transform: skew(-20deg); /* Firefox */
          //   -webkit-transform: skew(-20deg); /* Safari 和 Chrome */
          //   -o-transform: skew(-20deg);
          // }
        }
      }
      .contTitle {
        margin: 26px 0 18px;
        line-height: 24px;
      }
      .contItem {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        div {
          position: relative;
          width: 40%;
          padding: 0 16px;
          box-sizing: border-box;
          &::after {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            background-color: #1e50ae;
            left: 0;
            top: 46%;
            transform: rotate(45deg);
          }
        }
      }
    }
    .contRight {
      width: 420px;
      img {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .tabBox {
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-top: 26px;
    &::-webkit-scrollbar {
      display: none;
    }
    div {
      margin-right: 26px;
    }
    .selectSty {
      &::after {
        bottom: 0;
      }
    }
  }
  .center {
    padding: 0;
    .contBox {
      display: block;
      padding: 0;
      margin: 40px 0;
      .contLeft {
        width: 100%;
        line-height: 24px;
        padding: 0;
        .contImg {
          display: block;
          width: 100%;
          margin-top: 20px;
          img {
            width: 100%;
          }
        }
        .titleBox {
          text-align: center;
        }
        .contItem {
          padding: 0 0 10px;
          line-height: 26px;
          div {
            width: 50%;
          }
        }
      }
      .contRight {
        display: none;
      }
    }
  }
}
</style>
